<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">人才队伍</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">通过人员整合、业务拓展，专业综合能力持续提升，空间规划公司构建了城投集团规划建设板块，拥有了一支226人的高层次人才队伍，其中，空间规划公司160人，咨询中心4人，交通院62人。专业涵盖城乡规划、建筑学、土地资源、数字信息技术、测绘地理、市政工程、交通规划、环境工程等国家重点学科。其中本科及以上学历90%，大多毕业于“211”“985”等名牌高校，75%具有中级以上职称，国家注册执业资格人员占比50%，45岁以下青年人才占85%以上。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">空间规划公司聚集了“大连市政府特殊津贴专家”“大连市规划委员会专家”“辽宁省百千万人才”等核心人才。空间规划公司出台了专业技术人才引进管理办法，促进了人才科学引进与可持续发展。2021年1月空间规划公司建立了“博士后实践基地”等科研阵地。</span> 
        </p>
        <img width="80%" style="margin: 10px 10% 0" src="../../assets/htmlimg/博士后.jpg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>