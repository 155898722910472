import { render, staticRenderFns } from "./intro4.vue?vue&type=template&id=a53dd968&scoped=true"
import script from "./intro4.vue?vue&type=script&lang=js"
export * from "./intro4.vue?vue&type=script&lang=js"
import style0 from "./intro4.vue?vue&type=style&index=0&id=a53dd968&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a53dd968",
  null
  
)

export default component.exports